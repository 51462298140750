import React, { useState } from 'react';
import Select from 'commonModule/components/Select';
import { Button } from 'reactstrap';

import './styles.scss';
import { useTranslation } from 'react-i18next';


const Search = ({ getDiseaseSearchResultsAction, getSymptomListReducer }) => {
  const { t } = useTranslation();
  const initialState = {
    symptoms: [],
  };
  const [values, setValues] = useState(initialState);
  const handleSubmit = e => {
    e.preventDefault();
    const symptomIdList = values.symptoms.map(symptom => symptom._id).toString();
    getDiseaseSearchResultsAction(symptomIdList);
  };
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const renderSelect = () => (
    <Select
      inputId="search"
      className="search__select"
      value={values.symptoms}
      placeholder={t('searchComponent.typeSymptoms')}
      options={getSymptomListReducer.data}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option._id}
      isMulti
      onChange={(options) => handleChange('symptoms', options)}
    />
  );

  return (
    <form onSubmit={handleSubmit} className="search">
      {renderSelect()}
      <Button type="submit" color="secondary" disabled={!values?.symptoms?.length}>
        {t('searchComponent.search')}
      </Button>
    </form>
  );
};

export default Search;
