import React from 'react';
import { connect } from 'react-redux';

import { getDiseaseSearchResultsAction } from 'diseaseModule/redux/modules/getDiseaseSearchResultsModule';
import Search from 'diseaseModule/components/Search';

const SearchContainer = props => <Search {...props} />;

const mapStateToProps = ({ symptom: { getSymptomListReducer } }) => ({
  getSymptomListReducer,
});

const mapDispatchToProps = {
  getDiseaseSearchResultsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchContainer);
