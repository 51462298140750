import React from 'react';
import Layout from 'commonModule/components/Layout';
import { Jumbotron } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import PostList from 'postModule/components/PostList';
import SearchContainer from 'diseaseModule/containers/SearchContainer';

import './styles.scss';

const HomePage = ({ postListData = [] }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="home-page__search">
        <Jumbotron>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h1 className="pt-4 pb-4 display-4">{t('homePage.title')}</h1>
              <SearchContainer />
            </div>
          </div>
        </Jumbotron>
      </div>
      <div className="container">
        <div className="row">
          {/*			<div className="col-12">
						<h3 className="pt-4 pb-4">{t('homePage.symptomList')}:</h3>
						<List data={symptomListData} modelType={modelEnums.Symptom} />
					</div> */}
          <div className="col-12">
            <h3 className="pt-4 pb-4">
              {t('homePage.recentQuestionsList')}
              :
            </h3>
            <PostList showAnswersCounter hideVoteButtons data={postListData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
